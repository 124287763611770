import React, { Component } from 'react'
import ListingHeader from '../Common/ListingHeader'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from 'react-loader-spinner';
import { Button } from 'primereact/button';

export default class WorkflowListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workFlows: [],
            loading: false,
        }
    }


    componentDidMount() {

        this.getWorkflowData()


    }

    getWorkflowData = () => {
        this.setState({
            loading: true
        });

        var axios = require('axios');
        var config = {

            method: 'post',
            url: 'https://testapi.insurancegig.com/workflowapidevv2/api/workflow/listv2',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {}
        };
        axios(config)

            .then((response) => {
                this.setState({
                    loading: false,
                    workFlows: response.data,
                });
            })

            .catch((error) => {
                this.setState({
                    loading: false
                });
                console.log(error);
            });







    }

    onDetailsClick = (Id) => {
        // this.props.history.push(`/WorkflowDetail/${Id}`)
    }
    onKGClick = (Id) => {
        // this.props.history.push(`/WorkflowDetail/${Id}`)
        window.open(`/graph`, "_blank");
    }


    detailsTemplate = (rowData) => {
        return <Button className="p-button-primary"
            onClick={() => this.onDetailsClick(rowData.Id)}
        >Details</Button>;
    }

    partnerTemplate = (rowData) => {
        return rowData.partnerURLNames != null ? rowData.partnerURLNames.map((e, index) => {
            return (<>
                <img src={e} style={{ width: "25px" }} />&nbsp;{rowData.partnerNamesList[index]}<br /><br />
            </>);
        })
            : null





    }
    consumerTemplate = (rowData) => {
        return <img src={rowData.ConsumerURL} style={{ width: "150px" }}
        />;
    }

    graphTemplate = (rowData) => {
        return <a href="#" onClick={() => this.onKGClick(rowData.Id)}>KG</a>

    }

    nameTemplate = (rowData) => {
        return <a href="#" onClick={() => this.onDetailsClick(rowData.Id)}>{rowData.title}</a>;
    }

    htmlTemplate = (rowData) => {
        return <div dangerouslySetInnerHTML={{ __html: rowData.shortDescription }} />;
    }


    render() {

        return (
            <>
                <ListingHeader></ListingHeader>

                <section className="box-shadow-white-box-section grey-bg py-2" id="partner" style={{ backgroundColor: "#f5f2f2" }}>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid" >
                                <div className="row" >
                                    <div className="col-lg-12">
                                        <div className="box-shadow-white-box" style={{ textAlign: "center" }}>
                                            <h3 className="font-bold mb-3 mt-2 ">Workflows</h3>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.loading ?
                                        <div className="row" >
                                            <div className="col-lg-6">
                                                &nbsp;
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row auth-loader ">
                                                    <Loader type="TailSpin" color="#603c96" height="50" width="50" />Loading Workflow.....
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row" >
                                            <div className="col-lg-12">
                                                <div className="box-shadow-white-box-a ">
                                                    <DataTable value={this.state.workFlows}>
                                                        <Column style={{ width: "25%" }} header="Name" body={this.nameTemplate} sortable></Column>
                                                        <Column style={{ width: "40%" }} field="shortDescription" header="Description" body={this.htmlTemplate}></Column>
                                                        <Column header="Partners" body={this.partnerTemplate}></Column>
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </div>
                                }

                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}
