
import './assets/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';
import './assets/css/styles.css'
import './assets/css/slick.css';
import './assets/css/new-style.css';
import './assets/css/mid.css'; 
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";

import "react-datepicker/dist/react-datepicker.css";
import 'jquery/dist/jquery.min.js';


import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkflowDetail from './ListingPage/WorkflowDetail';
import Sales from './PicthBook/Sales';
// import Amplify from 'aws-amplify';
// import awsconfig from './aws-exports';

import WorkflowListing from './ListingPage/WorkflowListing';
// import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
// import Grpah from './ListingPage/Grpah';
// import GrpahV2 from './ListingPage/GrpahV2';

// Amplify.configure(awsconfig);


function App() {

  return (



    <div>
      {/* <AmplifyAuthenticator> */}
     

        <Router>

          <Switch>
            <Route
              path="/WorkflowDetail/:index"
              component={WorkflowDetail}
            ></Route>
            {/* <Route
              path="/sales"
              component={Sales}
            ></Route> */}
            

            <Route path="/" component={WorkflowListing} >
            </Route>

          </Switch>

        </Router>
        {/* <AmplifySignIn slot="sign-in" hideSignUp headerText="InsuranceGIG"/> */}
      {/* </AmplifyAuthenticator> */}
    </div>


  );
}

export default App;
